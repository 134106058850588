body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-pills .nav-link.active {
  background-color: #dae0e5;
}

.text-center {
  text-align: center;
}

/* Table */
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  cursor: pointer;
}

@media only screen and (min-width: 576px) {
  .DayPickerInput-Overlay {
    width: 450px;
  }
}

/* Datepicker */
.react-datepicker {
  display: flex !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

/* react-toastify override */
.Toastify__toast--error {
  background-color: #d9534f;
  border-radius: 10px;
  margin: 1rem;
  text-align: center;
}
.Toastify__toast--info {
  background-color: #5bc0de;
  border-radius: 10px;
  margin: 1rem;
  text-align: center;
}
.Toastify__toast--success {
  background-color: #5cb85c;
  border-radius: 10px;
  margin: 1rem;
  text-align: center;
}
.Toastify__toast--warning {
  background-color: #f0ad4e;
  border-radius: 10px;
  margin: 1rem;
  text-align: center;
}
/* Fixed column*/
.sticky {
  position: sticky;
  top: 0;
  padding: 0 15px;
}

@media only screen and (max-width: 768px) {
  .sticky {
    position: inherit;
  }
}

/* Info modal*/
.modal-scroll-body {
  max-height: 50vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
}

.modal-text {
  white-space: pre-line;
  word-break: break-word;
}

.modal-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.modal-right-text {
  float: right;
  color: gray;
  display: flex;
}

.modal-click-list:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/*Dropdown*/
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-height: 300px;
  min-width: 500px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media only screen and (max-width: 540px) {
  .dropdown-content {
    min-width: 320px;
    min-height: 430px;
  }
}

.display-none {
  display: none;
}

.dropdown:focus-within .dropdown-content {
  display: block;
}
